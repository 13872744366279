*,
*::before,
*::after {
	box-sizing: border-box;
}

*{
	font-family: Montserrat;
	color: var(--black);
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.flex {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

.--just-space {
	justify-content: space-between;
}

.--just-center {
	justify-content: center;
}

.--just-end {
	justify-content: flex-end;
}

.--align-str {
	align-items: stretch;
}

.--align-center {
	align-items: center;
}

.--align-end {
	align-items: flex-end;
}

.--dir-col {
	flex-direction: column;
}

html {
	font-size: 16px;
	height: 100%;
}

html,
body {
	min-height: 100%;
	position: relative;
}

body {
	font-size: 1rem;
}

main{
	padding-top: 67px;
	margin-top: 70px;
    padding-bottom: 340px;
}

ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

:root {
    --green: #52F238;
    --black: #040B19;
    --text-grey: #B6C1D7;
    --span-grey: #F4F6F9;
    --light-grey: #cacdd3;
    --grey-on-dark: #343A45;
    --grey: #707A8D;
    --dark-grey: #000000B3;
    --dark-blue: #1A2233;
}

body::-webkit-scrollbar, *:not(.scrolling)::-webkit-scrollbar {
	display: none;
}

p{font-size: 22px;}

.vertical-centered {
	display: flex;
    flex-direction: column;
    justify-content: center;
}

.centered-data{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
	width: 100%;
    max-width: 1200px;
    margin: 0 auto; 
}

.grey-background{background-color: var(--span-grey); position: relative;}

.bordered{ background-color: white; display: flex; align-items: center; color: var(--black); border: 2px solid var(--green); border-radius: 3px; font-weight: 550; width: fit-content; padding: 12px 34px; margin-top: 10px;}
.bordered:hover{border-color: var(--grey); color: var(--grey);}
.bordered:hover a{border-color: var(--grey);}

.two-cols{ display: flex; gap: 30px;}
p {line-height: 1.5;}

.page-content .centered-data{position: relative;}

h1,.class-h1, h2{font-size: 42px;}
h3{font-size: 28px;}
h1{margin-bottom: 36px;}
.class-h1, h1, h2, h3{font-weight: 600;}

a {text-decoration: none;}

ul{
	list-style-type: none;
	margin-bottom: 0;
	text-align: left;
}
ul.slashed-menu li{display: flex; align-items: center; font-size: 22px; font-weight: 700; line-height: 1.5;}
ul.slashed-menu li:not(:last-child){margin-bottom: 13px;}
ul.slashed-menu li>*{font-weight: 700; align-self: center; margin-top: auto; margin-bottom: auto;}
ul.slashed-menu a{color: var(--black); font-size: 22px; text-decoration: underline; font-weight: 700;}
ul.slashed-menu a:hover{text-decoration: none;}
ul.slashed-menu li::before {align-self: flex-start; font-size: 28px; content: "/"; color: var(--green);height: 100%; display: inline-block; vertical-align: middle; margin-right: 13px;}

ul.galk-menu{display: flex; line-height:1.5; flex-direction: column; flex-wrap: wrap; gap: 10px; height: 420px; font-size: 18px; }
.galk-menu li{display: flex; width: 370px;}
.galk-menu li:before{content: url(/img/galk.svg); width: 15px; height: 11px; display: inline-block; margin-top: 4px; margin-right:15px; }
.galk-menu li>p{font-size: 18px; line-height: 1.5; }

.green-gradient-color{background: linear-gradient(90deg, #4D93BB 1.4%, #52F238 98.97%);}
.green-gradient-color *{color: white;}
.green-gradient-color-text{-webkit-background-clip: text; -webkit-text-fill-color: transparent;}

.section{padding-top: 80px; padding-bottom: 80px;}
.two-cols {
    display: flex;
    gap: 30px;
}
.two-cols>*{flex: 1; flex-basis: calc(50% - 30px);}

main>*:last-child{margin-bottom: 100px;}
.heading-info{overflow: hidden; position: relative;}
.section-image, .heading-info img{position: absolute; right: 0;}
.heading-info img{top:0;}
.section-image{bottom: 0;}
.intro-word p {
    line-height: 1.5;
    max-width: 515px;
    font-size: 18px;
	padding-bottom: 80px;
}

.bolded{font-weight: 600;}
.centered-data.section{gap: 40px;}
.spaced-40{margin-bottom: 40px;}
.block-610{max-width: 610px;}
span.spaced{display: block;}
main *.height-fit-content{height: fit-content;}
ul.width-full>li{width: 100%;}

ol {
    max-width: 488px;
    font-size: 18px;
    line-height: 1.5;
}
ol > li {
    margin: 0;
    text-indent: -1em;
    list-style-type: none;
    counter-increment: item;
}
ol > li:before {
    display: inline-block;
    width: 1em;
    padding-right: 0.5em;
    font-weight: bold;
    text-align: right;
    content: counter(item) ".";
}
ol li:not(:first-child) {
    margin-top: 15px;
}
@media (max-width: 1240px){
	.centered-data {
		width: calc(100% - 48px);
		margin-left: 24px;
		margin-right: 24px;
	}
	main{padding-bottom: 500px;}
}

@media (max-width: 1100px){
	a.bordered {margin-top: 27px;}
	main {padding-top: 47px;}
	p {font-size: 18px;}
	h1, .class-h1, h2 {font-size: 36px;}
	main>*:last-child{margin-bottom: 70px;}
	ul.slashed-menu a{font-size: 20px;}
	.section-image, .heading-info img{opacity: 0.25;}
	.intro-word p{padding-bottom: 65px;}
	.section{padding-top: 65px; padding-bottom: 65px;}
	.img-in-text {opacity: 0.3;}
}

@media (max-width: 740px){
	ul.galk-menu{height: fit-content !important;}
}

@media (max-width: 675px){
    h1, .class-h1, h2{font-size: 28px;}
	h1, h2, h3 {
		text-align: center;
		margin-left: auto;
		margin-right: auto;
	}
	main>*:last-child{margin-bottom: 45px;}
	main{padding-bottom: 320px;}
	ul.slashed-menu a{font-size: 18px;}
	.intro-word p{padding-bottom: 60px;}
	.section{padding-top: 60px; padding-bottom: 60px;}
	.section p{text-align: center;}
	.two-cols{flex-direction: column;}
	ul.galk-menu{font-size: 16px;}
	.section-image, .heading-info img {
		max-height: 500px;
		object-fit: cover;
		object-position: top;
		left: -2px;
		margin-left: auto;
		margin-right: auto;
		top: unset;
		bottom: 0;
		width: 100%;
	}
	ol {margin-left: 30px;}
	.img-in-text {
		opacity: 1;
		position: relative !important;
		bottom: unset !important;
		right: unset !important;
		opacity: 1;
		margin-left: 15px;
		width: calc(100% - 24px);
		margin-top: 30px;
		margin-bottom: -25vw;
	}
}