.top-menu {
    position: fixed;
    top: 0;
    height: 70px;
    width: 100vw;
    opacity: 1;
    z-index: 10;
    background-color: white;
    box-shadow: 0 2px 2px -2px var(--grey);
}

.top-menu-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top-menu-data :not(.small-logo)>a {
    color: var(--black);
    padding: 8px 20px;
    position: relative;
}

.top-menu-data .center-menu>.menu-item:first-child {
    font-size: 12px;
}

.top-menu-data .menu-item {
    font-size: 13px;
}

.top-menu-data .menu-item .top-menu-data .top-inner-menu>.menu-item.selected a {
    color: var(--grey);
}

.top-menu-data .menu-item.is-parent {
    position: relative;
}

.top-menu-data>div>div>.menu-item.is-parent>a:after {
    content: "▼";
    position: absolute;
    right: 0;
    top: 8px;
    z-index: 1;
    text-align: center;
    width: 10%;
    height: 100%;
    pointer-events: none;
    color: var(--green);
}

.top-menu-data .menu-item:hover a:after,
.top-menu-data .menu-item.is-parent.selected a:after,
.top-menu-data>.menu-item.selected>a {
    color: var(--black);
}

.top-menu-data>div>div {
    align-self: center;
}

.top-menu-data>div.center-menu {
    /*width: 870px;*/
    height: 40px;
    position: relative;
    gap: 30px;
    justify-content: space-between;
    display: flex;
}

.top-menu-data>div.center-menu>div:not(.toggle-bar) {
    display: flex;
    gap: 30px;
}

.top-menu-data>div.center-menu>div>div:last-child {
    position: relative;
}

.top-menu-data>div.center-menu>div>div.opened {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
}

.top-menu-data div:hover {
    cursor: pointer;
}

.top-menu-data .menu-item:hover>a {
    color: var(--green);
}

.top-menu-data>div.social-icons {
    display: flex;
    flex-direction: row;
}

.top-menu-data .icons {
    width: 26px;
}

.top-menu-data>div.social-icons>div:first-child {
    margin-right: 8px;
}

.top-menu-data>div.social-icons a {
    padding: unset;
}

.top-menu-data .menu-item.is-parent:hover .top-inner-menu {
    display: block;
}

.top-menu-data .top-inner-menu {
    display: none;
    position: absolute;
    top: 31px;
    left: 0;
    min-width: 230px;
    background-color: var(--span-grey);
    opacity: 1;
}

.top-menu-data .top-inner-menu .menu-item:first-child {
    padding-top: 20px;
}

.top-menu-data .top-inner-menu .menu-item:last-child {
    padding-bottom: 15px;
}

.top-menu-data .top-inner-menu .menu-item a {
    color: var(--black);
    line-height: 1.4;
}

.top-menu-data .top-inner-menu .menu-item:hover a {
    color: var(--green);
}

.top-menu-data .center-menu>div:not(.search-div) {
    width: fit-content;
    transition: opacity 5s ease;
    opacity: 1;
}

/*  */
.top-menu-data .center-menu.unactive>div:not(.search-div) {
    width: 0;
    opacity: 0;
    transition: opacity 0s;
}

.top-menu-data .center-menu>div:not(.search-div) {
    transition: opacity 1.2s ease;
}

.top-menu-data .center-menu .search-div {
    font-size: 16px;
    min-width: 30px;
}

.top-menu-data .center-menu.unactive>.search-div {
    width: 100%;
    transition: width 0s;
    opacity: 1;
    padding: 8px 0;
}

.top-menu-data .center-menu.unactive>.search-div #title-search-input {
    width: calc(100% - 25px);
    padding: 8px 12px;
}

.top-menu-data .toggle-bar {
    display: none;
    position: relative;
}

.top-menu-data>.center-menu.unactive {
    gap: unset;
}

.top-menu-data .key-link {
    display: none;
    border-radius: 3px;
    border: 1px solid var(--green);
    min-width: 48px;
    width: 48px;
    height: 48px;
}

.top-menu-data .key-link a {
    padding: unset;
    align-items: center;
    justify-content: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

@media (max-width: 1500px) {
    .top-menu-data>div.center-menu>div>div:last-child>div{
        right: 0;
        left: unset;
        min-width: 145px;
        width: fit-content;
    }
    .top-menu-data .small-logo a{padding-left: unset;}
}

@media (min-width: 901px) {
    .top-menu-data .center-menu>div:nth-child(3)>.menu-item:nth-child(3)>a {
        padding: 8px 10px;
    }
}

@media (max-width: 900px) {
    .top-menu-data>div.center-menu {
        gap: unset;
        width: unset;
    }

    .top-menu-data .center-menu>.menu-item:first-child {
        margin-right: 20px;
    }

    .top-menu-data .center-menu.unactive>.menu-item:first-child {
        margin-right: unset;
    }

    .top-menu-data>div.center-menu>div:not(.toggle-bar) {
        display: none;
        top: 35px;
        background-color: var(--span-grey);
        width: 282px;
        flex-direction: column;
        gap: 0;
        position: absolute;
        right: 10px;
    }

    .center-menu>div>.menu-item:not(:nth-child(2)){padding: 5px 0;}
    //.center-menu>div>.menu-item .menu-item{padding: 2px 0;}


    .top-menu.open .top-menu-data>div.center-menu>div:not(.toggle-bar) {
        display: block;
    }

    .nav-toggle {
        box-sizing: border-box;
        border-radius: 5px;
        float: right;
        height: 38px;
        width: 38px;
        cursor: pointer;
        padding: 8px 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .hide-nav {
        transform: translateY(-120%) !important;
        -webkit-transform: translateY(-120%) !important;
    }

    .nav-toggle.open span:first-child {
        transform: rotate(45deg) translate(4.4px, 4.4px);
    }

    .nav-toggle.open span:nth-child(2) {
        width: 0%;
        opacity: 0;
    }

    .nav-toggle.open span:last-child {
        transform: rotate(-45deg) translate(4.4px, -4.4px);
    }

    .nav-toggle span {
        position: relative;
        display: block;
        height: 2px;
        width: 18px;
        margin-top: 4px;
        background-color: var(--green);
        transition: all .25s;
    }

    .top-menu-data .menu-item.is-parent:hover .top-inner-menu {
        display: none;
    }

    .top-menu-data .menu-item.is-parent.selected a:not(.active):after {
        color: var(--green);
    }

    .top-menu-data .toggle-bar {
        display: block;
    }

    .top-menu-data .center-menu.unactive .toggle-bar {
        margin-right: unset;
    }

    .top-menu-data .center-menu .active {
        color: var(--grey);
    }

    .top-menu-data .center-menu .menu-item.is-parent>a.active:after {
        transform: rotate(180deg);
        top: -6px;
        color: var(--grey);
    }

    .top-menu-data .center-menu .active+.top-inner-menu {
        display: block !important;
    }

    .top-menu-data .top-inner-menu {
        position: relative;
        top: 10px;
    }

    .top-menu-data .top-inner-menu .menu-item:first-child {
        padding-top: unset;
    }

    .top-menu-data .top-inner-menu .menu-item {
        padding-left: 20px;
    }

    .top-menu-data .top-inner-menu a {
        padding: 5px 20px;
    }
}

@media (max-width: 675px) {
    .top-menu-data .center-menu>div:not(.search-div) {
        transition: unset;
    }

    .top-menu.open div.center-menu>div:nth-child(3) {
        width: 100%;
        left: 0;
        top: 100%;
    }

    .top-menu-data .key-link {
        display: block;
    }

    .top-menu-data .center-menu>.menu-item:first-child {
        display: none;
    }

    .top-menu-data>div.center-menu {
        position: unset;
    }

    .top-menu-data .center-menu.unactive>div:not(.search-div) {
        width: unset;
        opacity: 1;
    }

    .top-menu-data .toggle-bar {
        margin-right: unset;
    }

    .top-menu .top-menu-data>div.center-menu {
        gap: 20px;
    }

    .top-menu.open .top-menu-data>div.center-menu>div:not(.toggle-bar){
        top: 50px;
        right: 34px;
    }
}

@media(max-width: 375px) {
    .top-menu .top-menu-data>div.center-menu {
        gap: 10px;
    }
}