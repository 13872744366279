footer{
	height: 340px;
	width: 100%;
	position: absolute;
	bottom: 0;
	right:0;
	background-color: var(--black);
	color: white;
	box-sizing: border-box;
}
footer a{color: white;}
footer .footer-content{display: flex; justify-content: space-between;}
footer .footer-content>*:first-child{width: 169px;}
footer .footer-content>*:first-child>span{font-size: 11px; overflow-x: visible;}

footer .footer-content>*:first-child>*:first-child{margin-bottom: 22px; font-weight: 600;}
footer .footer-menus>*>*:first-child{margin-bottom: 22px;}
footer .footer-menus>*>*>a{font-size: 13px; line-height: 24px; font-weight: 700; margin-bottom: 22px;}
footer .footer-menus{display: flex; flex-basis:60%; justify-content: space-between;}
footer .footer-menus .products-menu{flex-basis:45%;}
footer .footer-menus a:hover{color: var(--green);}

footer .specific-margin{margin-top: 45px; display: flex; flex-direction: column;}
footer .specific-margin a{font-size: 11px; color: var(--grey);}
footer .specific-margin a:hover{text-decoration: underline;}
footer .franchise{width: 69px; height: 42px; margin-bottom: 15px;}

footer .footer-content>*:last-child{justify-content: flex-start; align-items: flex-end; width: 167px;}
footer .footer-content a.number{font-size: 18px; font-weight: 600;}
footer .footer-content a.number:nth-child(2){margin-bottom: 10px;}
footer .footer-content a.number:hover{color: white; text-decoration: underline;}
footer .footer-content a.email{color:var(--green);font-size: 13px;}
footer .footer-content a.email:hover{text-decoration: underline;}
footer .personal-link{border-radius: 3px; width: fit-content; height: fit-content; display: flex;}
footer .personal-link a{padding: 8px 20px; font-size: 12px;}
footer .personal-link a:hover{color: var(--grey);}

.bottom-menu .bottom-menu-data{display: flex; flex-direction: column; flex-wrap: wrap; height: 158px;}
.bottom-menu .bottom-menu-data .menu-item:not(:last-child){margin-bottom: 10px;}
.bottom-menu-data .menu-item a{font-size: 13px;}

@media(max-width: 1240px){
    footer {height: 500px;}
    footer .footer-menus {flex-wrap: wrap; flex-basis: 45%;}
	footer .footer-menus>* {display: flex; flex-direction: column;}
	footer .footer-menus .products-menu{order: 0; width: 100%; flex-basis: unset;}
	footer .footer-menus .products-menu .menu-item{width: 50%;}
	footer .footer-menus .services-menu{order: 1; flex: 1; margin-top: 30px;}
	footer .footer-menus .company-menu{order: 2; flex: 1; margin-top: 30px;}
}

@media (max-width: 675px){
    .footer-menus {display: none !important;}
    footer {height: 320px;}
    footer .footer-content a.number{font-size: 17px;}
}